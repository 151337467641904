import logo from "../../assets/logo-main-light.svg";
import React, { useEffect } from "react";
import { Space, ConfigProvider, Layout, theme } from "antd";
import { useNavigate } from "react-router-dom";

import FormLogin from "../../components/forms/formLogin";

export default function Auth() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/app");
    }
  });

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#30EFD3",
          borderRadius: 6,
          colorLink: "#30EFD3",
        },
        components: {
          Layout: {
            bodyBg: "#0D1321",
          },
        },
      }}
    >
      <Layout>
        <Space
          direction="vertical"
          align="center"
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "end",
              gap: 15,
            }}
          >
            <img src={logo} alt="Mailinflow Logo" height={60} />
          </div>
          <FormLogin />
        </Space>
      </Layout>
    </ConfigProvider>
  );
}
