import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { contacts, tags } from "../slices/contactsSlice";

export const listContacts = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    privateGetApi("/contacts", payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(contacts(response.data.result));
          resolve();
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const listTags = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    privateGetApi("/contacts/tags", payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(tags(response.data.result));
          resolve();
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createContact = (payload) => () => {
  return new Promise((resolve, reject) => {
    privatePostApi("/contacts", payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readContact = (payload, id) => () => {
  return new Promise((resolve, reject) => {
    privateGetApi("/contacts/" + id, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateContact = (payload, id) => () => {
  return new Promise((resolve, reject) => {
    privatePutApi("/contacts/" + id, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteContact = (id) => () => {
  return new Promise((resolve, reject) => {
    privateDeleteApi("/contacts/" + id)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
