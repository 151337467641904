import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../utils/apiHelper";
import { campaigns } from "../slices/campaignsSlice";

export const listCampaigns = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    privateGetApi("/campaigns", payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(campaigns(response.data.result));
          resolve(response.data.result);
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createCampaign = (payload) => () => {
  return new Promise((resolve, reject) => {
    privatePostApi("/campaigns", payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readCampaign = (payload, id) => () => {
  return new Promise((resolve, reject) => {
    privateGetApi("/campaigns/" + id, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateCampaign = (payload, id) => () => {
  return new Promise((resolve, reject) => {
    privatePutApi("/campaigns/" + id, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCampaign = (id) => () => {
  return new Promise((resolve, reject) => {
    privateDeleteApi("/campaigns/" + id)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
