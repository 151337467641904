import React from "react";
import { EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Col, Select, Drawer, Form, Input, Row, Space } from "antd";

import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createContact,
  updateContact,
} from "../../redux/actions/contactsAction";

export default function FormContact({ type, formData, fetchRecords }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const onSubmit = (values) => {
    if (type === "create") {
      dispatch(createContact(values))
        .then(() => {
          fetchRecords();
          setOpen(false);
          form.resetFields();
        })
        .catch((e) => console.log(e));
    } else {
      dispatch(updateContact(values, formData._id))
        .then(() => {
          fetchRecords();
          setOpen(false);
          form.resetFields();
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      {type === "create" ? (
        <Button
          icon={<UserAddOutlined />}
          title="Add Contact"
          onClick={showDrawer}
        >
          Add
        </Button>
      ) : (
        <Button type="link" onClick={showDrawer} title="Edit Contact">
          <EditOutlined />
        </Button>
      )}
      <Drawer
        title={type === "create" ? "New Contact" : "Edit Contact"}
        width={720}
        placement="right"
        maskClosable={false}
        destroyOnClose={true}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={form.submit}>
              Save
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ ...formData }}
          onFinish={onSubmit}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter first name",
                  },
                ]}
              >
                <Input placeholder="Please enter first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: false,
                    whitespace: true,
                    message: "Please enter last name",
                  },
                ]}
              >
                <Input placeholder="Please enter last name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="emailAddress"
                label="Email Address"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter email address",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input placeholder="Please enter email address" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  {
                    required: false,
                    message: "Please enter phone number",
                  },
                  {
                    pattern: new RegExp("^[0-9]{10,11}$", "g"),
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <Input placeholder="Please enter phone number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="tags"
                label="Tags"
                rules={[
                  {
                    required: true,
                    message: "Please enter contact tags",
                  },
                ]}
              >
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please enter contact tags"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="remarks" label="Your Remarks">
                <Input.TextArea
                  rows={4}
                  placeholder="Please enter your remarks, notes or comments"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
