import homeBanner from "../../assets/home-banner-2.png";
import logo from "../../assets/logo-dark.svg";
import logoAccenture from "../../assets/logo-accenture.png";
import logoLabournet from "../../assets/logo-labournet.png";
import redmonklogo from "../../assets/redmonk-light.svg";
import bannerAnalytics from "../../assets/banner-analytics_min.webp";
import ImportContacts from "../../assets/import-contacts_min.webp";
import ComposeMessage from "../../assets/compose-message_min.webp";
import SendWithEase from "../../assets/send-with-ease_min.webp";
import EasySteps from "../../assets/easy-steps.svg";
import React from "react";
import {
  ConfigProvider,
  Typography,
  Button,
  Row,
  Col,
  Timeline,
  Card,
  Space,
  Flex,
  Avatar,
  Layout,
  Collapse,
  theme,
} from "antd";
import {
  UsergroupAddOutlined,
  IdcardOutlined,
  SignatureOutlined,
  SoundOutlined,
} from "@ant-design/icons";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

const { Title, Text, Paragraph } = Typography;

const LandingPage = () => {
  const features = [
    {
      icon: <UsergroupAddOutlined />,
      title: "Contact Management",
      description:
        "Manage your contacts effortlessly. Import, add, or edit your contacts quickly and efficiently.",
    },
    {
      icon: <IdcardOutlined />,
      title: "Contact Lists",
      description:
        "Organize your contacts into lists or groups. Categorize your audience for targeted messaging.",
    },
    {
      icon: <SignatureOutlined />,
      title: "Quick Sending",
      description:
        "Send your emails with minimal steps. Just compose, select your list, and hit send!",
    },
    {
      icon: <SoundOutlined />,
      title: "Bulk Emailing",
      description:
        "Easily send bulk emails to your contacts without the need for templates. Compose your message and reach your audience in no time.",
    },
    {
      icon: <UsergroupAddOutlined />,
      title: "Easy to Use",
      description:
        "Our tool is designed with simplicity in mind. You don't need email templates, tracking, or automation. Just focus on sending your messages.",
    },
  ];
  const howItWorks = [
    {
      avatar: ImportContacts,
      title: "Step 1: Import Contacts",
      description:
        "Easily import your existing contacts or add new ones effortlessly.",
      children: (
        <>
          <Title level={4}>Import Contacts</Title>
          <Paragraph>
            Easily import your existing contacts or add new ones effortlessly.
          </Paragraph>
        </>
      ),
    },
    {
      avatar: ComposeMessage,
      title: "Step 2: Compose Message",
      description: "Craft your email message without the need for templates.",
      children: (
        <>
          <Title level={4}>Compose Message</Title>
          <Paragraph>
            Craft your email message without the need for templates.
          </Paragraph>
        </>
      ),
    },
    {
      avatar: SendWithEase,
      title: "Step 3: Send with Ease",
      description:
        "Select your contact list and hit send to reach your audience instantly.",
      children: (
        <>
          <Title level={4}>Send with Ease</Title>
          <Paragraph>
            Select your contact list and hit send to reach your audience
            instantly.
          </Paragraph>
        </>
      ),
    },
  ];
  const accessToken = localStorage.getItem("accessToken");

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#30EFD3",
          borderRadius: 6,
          colorLink: "#30EFD3",
        },
        components: {
          Layout: {
            bodyBg: "#0D1321",
          },
        },
      }}
    >
      <Layout>
        <div
          className="container"
          style={{ position: "fixed", left: 0, right: 0, zIndex: 999 }}
        >
          <header
            style={{
              background: "#fffcff",
              marginTop: "1rem",
              borderRadius: "6px",
              padding: "0.5rem",
            }}
          >
            <Flex gap="large" align="center" justify="space-between" wrap>
              <img
                src={logo}
                alt="Mailinflow Logo"
                height={30}
                style={{ marginLeft: "0.25rem" }}
              />
              <Flex gap="middle" align="center" justify="flex-start">
                <Button type="link" href="/">
                  Home
                </Button>
                <Button type="link" href="/about">
                  About
                </Button>
                <Button type="link" href="/pricing">
                  Pricing
                </Button>
              </Flex>
              {accessToken ? (
                <Button type="primary" href="/app">
                  Go to Dashboard
                </Button>
              ) : (
                <Flex gap="middle" align="center" justify="flex-start">
                  <Button type="default" href="/login">
                    Login
                  </Button>
                  <Button type="primary" href="/login">
                    Get Started
                  </Button>
                </Flex>
              )}
            </Flex>
          </header>
        </div>

        <main>
          <div className="container-fluid">
            <section
              style={{
                padding: "4rem 0",
                textAlign: "center",
                backgroundImage: `url(${homeBanner})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <Flex
                gutter={30}
                align="center"
                justify="center"
                style={{
                  minHeight: "60vh",
                }}
              >
                <Col sm={24} md={12}>
                  <Title
                    style={{
                      fontSize: "48px",
                      marginBottom: "2.5rem",
                    }}
                  >
                    Effortless Email Marketing
                  </Title>

                  <Paragraph
                    style={{
                      lineHeight: "1.5",
                    }}
                  >
                    Simplify your email campaigns and focus on building
                    connections. Mailinflow makes bulk emailing and contact
                    management a breeze.
                  </Paragraph>

                  <Space size="large">
                    <Button type="primary" size="large" href="/login">
                      Get Started
                    </Button>
                    <Button
                      type="default"
                      size="large"
                      href="https://cal.com/d/uWZdkWEAbzT3L547YuKfua/book-a-demo-mailinflow"
                      target="_blank"
                    >
                      Book a Demo
                    </Button>
                  </Space>
                </Col>
              </Flex>
            </section>
          </div>

          <div className="container">
            <section
              style={{
                padding: "4rem 0",
                textAlign: "center",
              }}
            >
              <Space direction="vertical" size="large">
                <Flex gap={30} align="flex-end" justify="center">
                  <img
                    src={logoAccenture}
                    alt="accenture"
                    height={20}
                    className="img-grayscale"
                  />
                  <img
                    src={logoLabournet}
                    alt="labournet"
                    height={14}
                    className="img-grayscale"
                  />
                </Flex>
                <Paragraph type="secondary">
                  Trusted by top-tier product companies
                </Paragraph>
              </Space>
            </section>

            <section
              style={{
                padding: "4rem 0",
                textAlign: "center",
              }}
            >
              <Title
                level={2}
                style={{
                  marginBottom: "2.5rem",
                }}
              >
                Services included in every plan
              </Title>

              <Row gutter={[16, 16]} align="center">
                {features.map((feature) => (
                  <Col xs={24} sm={12} lg={8}>
                    <Card
                    // style={{
                    //   padding: "20px",
                    //   textAlign: "center",
                    // }}
                    >
                      <Avatar
                        size={64}
                        shape="square"
                        icon={feature.icon}
                        style={{ color: "#30EFD3" }}
                      />
                      <Title level={4}>{feature.title}</Title>
                      <Paragraph>{feature.description}</Paragraph>
                    </Card>
                  </Col>
                ))}
              </Row>
            </section>

            <section
              style={{
                padding: "4rem 0",
              }}
            >
              <Flex gutter={[16, 16]} align="center" justify="center">
                <Col xs={24} sm={24} md={12} style={{ textAlign: "center" }}>
                  <img
                    src={EasySteps}
                    alt="Mailinflow Screen"
                    style={{ width: "70%" }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Title
                    level={2}
                    style={{ textAlign: "center", marginBottom: "2.5rem" }}
                  >
                    3 quick steps to use our services
                  </Title>
                  <Timeline items={howItWorks} />
                </Col>
              </Flex>
            </section>

            <section
              style={{
                padding: "4rem 0",
              }}
            >
              <Title
                level={2}
                style={{
                  marginBottom: "2.5rem",
                  textAlign: "center",
                }}
              >
                Frequently Asked Questions
              </Title>
              <Row gutter={30} align="center">
                <Col sm={24} md={12}>
                  <Space direction="vertical" size="large">
                    <Collapse
                      collapsible="header"
                      defaultActiveKey={["1"]}
                      items={[
                        {
                          key: "1",
                          label: "What is Mailinflow?",
                          children: (
                            <p>
                              Mailinflow is a streamlined email marketing tool
                              that simplifies bulk emailing and contact
                              management, allowing you to focus on engaging your
                              audience without the complexity of traditional
                              email marketing platforms.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "2",
                          label:
                            "Do I need to create templates to send emails?",
                          children: (
                            <p>
                              No, Mailinflow eliminates the need for email
                              templates. You can compose your message directly
                              and send it to your audience effortlessly.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "3",
                          label: "Can I import my existing contact lists?",
                          children: (
                            <p>
                              Yes, you can easily import your existing contacts
                              or add new ones effortlessly through the contact
                              management system.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "4",
                          label: "What kind of support does Mailinflow offer?",
                          children: (
                            <p>
                              We provide dedicated support to assist with any
                              issues you may encounter. You can also book a demo
                              to better understand how to use the platform.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "5",
                          label: "Is Mailinflow suitable for beginners?",
                          children: (
                            <p>
                              Absolutely! Mailinflow is designed with simplicity
                              in mind. You don't need prior experience with
                              email marketing to use our platform.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "6",
                          label:
                            "Can I categorize my contacts into different groups?",
                          children: (
                            <p>
                              Yes, you can organize your contacts into lists or
                              groups for targeted messaging.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "7",
                          label: "Is there a free trial available?",
                          children: (
                            <p>
                              Yes, Mailinflow offers a free trial so you can
                              experience our services before committing to a
                              plan.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "8",
                          label: "How quickly can I send emails?",
                          children: (
                            <p>
                              With Mailinflow’s quick send feature, you can send
                              emails with minimal steps—just compose, select
                              your list, and hit send.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "9",
                          label:
                            "Are there any limits on the number of emails I can send?",
                          children: (
                            <p>
                              The limits depend on the plan you choose. Check
                              out our pricing section for details on the
                              features and limits of each plan.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "10",
                          label: "What industries can benefit from Mailinflow?",
                          children: (
                            <p>
                              Mailinflow is perfect for businesses across
                              industries, especially those needing efficient
                              email communication, such as marketing agencies,
                              startups, and e-commerce companies.
                            </p>
                          ),
                        },
                      ]}
                    />
                  </Space>
                </Col>
              </Row>
            </section>

            <section
              style={{
                padding: "4rem 0",
              }}
            >
              <Space
                direction="vertical"
                align="center"
                style={{
                  //   background: "#fff0f6",
                  //   padding: "40px",
                  textAlign: "center",
                  //   borderRadius: "10px",
                  display: "flex",
                }}
              >
                <Title
                  level={2}
                  style={{
                    marginBottom: "2.5rem",
                  }}
                >
                  Start Your Email Marketing Journey Today!
                </Title>
                <Paragraph>
                  Sign up during our soft launch and enjoy a free daily limit of
                  100 emails—exclusively for early adopters.
                </Paragraph>
                <Button type="primary" size="large" href="/login">
                  Get Started
                </Button>
              </Space>
            </section>
          </div>
        </main>

        <footer>
          <Flex
            gap="small"
            align="center"
            vertical
            style={{ padding: "4rem 2rem 2rem" }}
          >
            <Text style={{ marginBottom: "0.25rem", fontSize: "65%" }}>
              Powered By
            </Text>
            <img src={redmonklogo} alt="Redmonk Logo" height={40} />
          </Flex>
          <Flex
            gap="large"
            align="center"
            justify="center"
            style={{ padding: "1rem 2rem 0" }}
            wrap
          >
            <Button
              type="link"
              href="https://redmonkstudios.com/about"
              target="_blank"
            >
              Our Studio
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/contact-us"
              target="_blank"
            >
              Contact
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/terms-of-service"
              target="_blank"
            >
              Terms of Service
            </Button>
          </Flex>
          <Flex
            gap="large"
            align="center"
            justify="center"
            style={{ padding: "1rem 2rem 0" }}
          >
            <Paragraph>
              &copy; Copyrights {new Date().getFullYear()} Redmonk Studios (OPC)
              Private Limited. All rights reserved.
            </Paragraph>
          </Flex>
        </footer>
      </Layout>
    </ConfigProvider>
  );
};

export default LandingPage;
