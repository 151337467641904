import React from "react";
import {
  Space,
  ConfigProvider,
  Result,
  Button,
  Layout,
  message,
  theme,
} from "antd";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unsubscribeUser } from "../../redux/actions/publicAction";

export default function Unsubscribe() {
  const dispatch = useDispatch();
  const { emailAddress, userEmailAddress, campaignId } = useParams();

  const handleUnsubscribe = () => {
    dispatch(
      unsubscribeUser({
        emailAddress,
        userEmailAddress,
        campaignId,
      })
    )
      .then((response) => {
        message.success(response.message);
      })
      .catch((e) => console.log(e));
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#30EFD3",
          borderRadius: 6,
          colorLink: "#30EFD3",
        },
        components: {
          Layout: {
            bodyBg: "#0D1321",
          },
        },
      }}
    >
      <Layout>
        <Space
          direction="vertical"
          align="center"
          style={{ display: "flex", height: "100vh", justifyContent: "center" }}
        >
          <Result
            status="error"
            title="Unsubscribe from Email List"
            subTitle="We're sorry to see you go! Please confirm your decision to unsubscribe from our email list."
            extra={[
              <Button type="primary" onClick={() => handleUnsubscribe()}>
                Unsubscribe
              </Button>,
            ]}
          ></Result>
        </Space>
      </Layout>
    </ConfigProvider>
  );
}
