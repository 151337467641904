import { publicGetApi } from "../../utils/apiHelper";

export const unsubscribeUser = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    publicGetApi(
      `/unsubscribe/${payload.emailAddress}/${payload.userEmailAddress}/${payload.campaignId}`
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
