import logo from "../../assets/logo-dark.svg";
import redmonklogo from "../../assets/redmonk-light.svg";
import EmpoweringStartups from "../../assets/empowering-startups.svg";
import pageBanner from "../../assets/page-banner-2.png";
import {
  ConfigProvider,
  Typography,
  Button,
  Row,
  Col,
  Timeline,
  Card,
  Space,
  Flex,
  Avatar,
  Layout,
  Collapse,
  theme,
} from "antd";

const { Title, Text, Paragraph } = Typography;

const AboutUs = () => {
  const accessToken = localStorage.getItem("accessToken");

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#30EFD3",
          borderRadius: 6,
          colorLink: "#30EFD3",
        },
        components: {
          Layout: {
            bodyBg: "#0D1321",
          },
        },
      }}
    >
      <Layout>
        <div
          className="container"
          style={{ position: "fixed", left: 0, right: 0, zIndex: 999 }}
        >
          <header
            style={{
              background: "#fffcff",
              marginTop: "1rem",
              borderRadius: "6px",
              padding: "0.5rem",
            }}
          >
            <Flex gap="large" align="center" justify="space-between" wrap>
              <img
                src={logo}
                alt="Mailinflow Logo"
                height={30}
                style={{ marginLeft: "0.25rem" }}
              />
              <Flex gap="middle" align="center" justify="flex-start">
                <Button type="link" href="/">
                  Home
                </Button>
                <Button type="link" href="/about">
                  About
                </Button>
                <Button type="link" href="/pricing">
                  Pricing
                </Button>
              </Flex>
              {accessToken ? (
                <Button type="primary" href="/app">
                  Go to Dashboard
                </Button>
              ) : (
                <Flex gap="middle" align="center" justify="flex-start">
                  <Button type="default" href="/login">
                    Login
                  </Button>
                  <Button type="primary" href="/login">
                    Get Started
                  </Button>
                </Flex>
              )}
            </Flex>
          </header>
        </div>

        <main
          style={{
            backgroundImage: `url(${pageBanner})`,
            backgroundSize: "250px",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            backgroundRepeat: "repeat",
          }}
        >
          <div className="container">
            <section
              style={{
                padding: "10rem 0",
                textAlign: "center",
              }}
            >
              <Row gutter={30} align="center">
                <Col sm={24} md={12}>
                  <img
                    src={EmpoweringStartups}
                    alt="Mailinflow Screen"
                    style={{ width: "50%" }}
                  />
                  <Title
                    style={{
                      fontSize: "48px",
                      marginBottom: "2.5rem",
                    }}
                  >
                    Empowering Startups, One Email at a Time
                  </Title>

                  <Title level={3}>
                    From a simple idea to a powerful tool for startups,
                    Mailinflow is here to revolutionize email marketing without
                    breaking the bank.
                  </Title>

                  {/* <Button
                    type="default"
                    size="large"
                    href="https://cal.com/d/uWZdkWEAbzT3L547YuKfua/book-a-demo-mailinflow"
                    target="_blank"
                  >
                    Get in Touch
                  </Button> */}
                  <Paragraph style={{ marginTop: "2rem" }}>
                    Mailinflow is a testament to the vision of Samita Mondal,
                    founder of Redmonk Studios, who is dedicated to empowering
                    startups with affordable, efficient tools. Recognizing the
                    challenges small businesses face with complex and costly
                    email marketing solutions, Samita developed Mailinflow to
                    simplify bulk emailing and contact management, enabling
                    startups to focus on meaningful audience engagement.
                  </Paragraph>
                  <Paragraph>
                    At Redmonk Studios, we specialize in crafting exceptional
                    digital experiences. Our expertise in web design,
                    development, and creative consultancy allows us to bring
                    innovative ideas to life, helping businesses thrive in the
                    digital landscape.
                  </Paragraph>
                  <Paragraph>
                    Mailinflow embodies our commitment to delivering
                    user-friendly, cost-effective solutions that drive
                    productivity and growth for startups and small businesses.
                  </Paragraph>
                </Col>
              </Row>
            </section>
          </div>
        </main>

        <footer>
          <Flex
            gap="small"
            align="center"
            vertical
            style={{ padding: "4rem 2rem 2rem" }}
          >
            <Text style={{ marginBottom: "0.25rem", fontSize: "65%" }}>
              Powered By
            </Text>
            <img src={redmonklogo} alt="Redmonk Logo" height={40} />
          </Flex>
          <Flex
            gap="large"
            align="center"
            justify="center"
            style={{ padding: "1rem 2rem 0" }}
            wrap
          >
            <Button
              type="link"
              href="https://redmonkstudios.com/about"
              target="_blank"
            >
              Our Studio
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/contact-us"
              target="_blank"
            >
              Contact
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/terms-of-service"
              target="_blank"
            >
              Terms of Service
            </Button>
          </Flex>
          <Flex
            gap="large"
            align="center"
            justify="center"
            style={{ padding: "1rem 2rem 0" }}
          >
            <Paragraph>
              &copy; Copyrights {new Date().getFullYear()} Redmonk Studios (OPC)
              Private Limited. All rights reserved.
            </Paragraph>
          </Flex>
        </footer>
      </Layout>
    </ConfigProvider>
  );
};

export default AboutUs;
