import { createSlice } from "@reduxjs/toolkit";

export const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    contacts: [],
    contactCount: 0,
    tags: [],
  },
  reducers: {
    contacts: (state, action) => {
      state.contacts = action.payload.records;
      state.contactCount = action.payload.count;
    },
    tags: (state, action) => {
      state.tags = action.payload.records;
    },
  },
});

// Action creators are generated for each case reducer function
export const { contacts, tags } = contactsSlice.actions;

export default contactsSlice.reducer;
