import { createSlice } from "@reduxjs/toolkit";

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: {
    campaigns: [],
    campaignCount: 0,
  },
  reducers: {
    campaigns: (state, action) => {
      state.campaigns = action.payload.records;
      state.campaignCount = action.payload.count;
    },
  },
});

// Action creators are generated for each case reducer function
export const { campaigns } = campaignsSlice.actions;

export default campaignsSlice.reducer;
