import { configureStore } from "@reduxjs/toolkit";
import contactsSlice from "./slices/contactsSlice";
import campaignsSlice from "./slices/campaignsSlice";

export default configureStore({
  reducer: {
    contacts: contactsSlice,
    campaigns: campaignsSlice,
  },
});
