import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Space,
  Button,
  Statistic,
  Divider,
  Drawer,
  Typography,
  Empty,
  Descriptions,
  Progress,
} from "antd";
import {
  EyeOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { readCampaign } from "../../redux/actions/campaignsAction";

import FormCampaign from "../../components/forms/formCampaign";
import DeleteRecord from "../../components/modals/deleteRecord";
import SendEmail from "../../components/modals/sendEmail";

const { Title, Text, Paragraph } = Typography;

export default function ViewCampaign({ campaignId, fetchRecords }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [campaign, setCampaign] = useState(null);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const sendEmailAndClose = () => {
    fetchRecords();
    onClose();
  };

  useEffect(() => {
    if (open) {
      dispatch(readCampaign({}, campaignId))
        .then((response) => {
          setCampaign(response);
        })
        .catch((e) => console.log(e));
    }
  }, [open]);

  return (
    <>
      <Button type="text" onClick={showDrawer} title="View Campaign">
        <EyeOutlined />
      </Button>
      <Drawer
        title="View Campaign"
        width={720}
        placement="right"
        maskClosable={true}
        destroyOnClose={true}
        onClose={onClose}
        open={open}
        extra={
          !campaign
            ? []
            : [
                <DeleteRecord
                  id={campaign._id}
                  collection="campaigns"
                  fetchRecords={fetchRecords}
                />,
                campaign.status.description === "Draft" && (
                  <FormCampaign
                    type="edit"
                    formData={campaign}
                    fetchRecords={fetchRecords}
                  />
                ),
                campaign.status.description === "Draft" && (
                  <SendEmail
                    id={campaign._id}
                    subject={campaign.subject}
                    fetchRecords={sendEmailAndClose}
                  />
                ),
              ]
        }
      >
        {!campaign ? (
          <Empty
            description={<Paragraph>There is no data to display.</Paragraph>}
          />
        ) : (
          <>
            <Space
              direction="vertical"
              size="large"
              style={{ display: "flex" }}
            >
              {campaign.status.description === "Sent" && (
                <>
                  <Row gutter={[30, 30]}>
                    <Col xs={24} md={12}>
                      <Card bordered={true} style={{ display: "block" }}>
                        <Statistic
                          title={"Opens (" + campaign.opened + ")"}
                          value={
                            (campaign.opened / campaign.totalContacts) * 100
                          }
                          valueStyle={{
                            color:
                              (campaign.opened / campaign.totalContacts) * 100 >
                              50
                                ? "#3f8600"
                                : "#cf1322",
                          }}
                          precision={2}
                          prefix={
                            (campaign.opened / campaign.totalContacts) * 100 >
                            50 ? (
                              <ArrowUpOutlined />
                            ) : (
                              <ArrowDownOutlined />
                            )
                          }
                          suffix="%"
                        />
                      </Card>
                    </Col>
                    <Col xs={24} md={12}>
                      <Card bordered={true} style={{ display: "block" }}>
                        <Statistic
                          title={"Clicks (" + campaign.clicked + ")"}
                          value={
                            (campaign.clicked / campaign.totalContacts) * 100
                          }
                          valueStyle={{
                            color:
                              (campaign.clicked / campaign.totalContacts) *
                                100 >
                              ((campaign.opened / campaign.totalContacts) *
                                100) /
                                2
                                ? "#3f8600"
                                : "#cf1322",
                          }}
                          precision={2}
                          prefix={
                            (campaign.clicked / campaign.totalContacts) * 100 >
                            ((campaign.opened / campaign.totalContacts) * 100) /
                              2 ? (
                              <ArrowUpOutlined />
                            ) : (
                              <ArrowDownOutlined />
                            )
                          }
                          suffix="%"
                        />
                      </Card>
                    </Col>
                    <Col xs={24} md={12}>
                      <Card bordered={true} style={{ display: "block" }}>
                        <Statistic
                          title={
                            "Unique Opens (" + (campaign.uniqueOpens || 0) + ")"
                          }
                          value={
                            ((campaign.uniqueOpens || 0) /
                              campaign.totalContacts) *
                            100
                          }
                          valueStyle={{
                            color:
                              ((campaign.uniqueOpens || 0) /
                                campaign.totalContacts) *
                                100 >
                              50
                                ? "#3f8600"
                                : "#cf1322",
                          }}
                          precision={2}
                          prefix={
                            ((campaign.uniqueOpens || 0) /
                              campaign.totalContacts) *
                              100 >
                            50 ? (
                              <ArrowUpOutlined />
                            ) : (
                              <ArrowDownOutlined />
                            )
                          }
                          suffix="%"
                        />
                      </Card>
                    </Col>
                    <Col xs={24} md={12}>
                      <Card bordered={true} style={{ display: "block" }}>
                        <Statistic
                          title={
                            "Unique Clicks (" +
                            (campaign.uniqueClicks || 0) +
                            ")"
                          }
                          value={
                            ((campaign.uniqueClicks || 0) /
                              campaign.totalContacts) *
                            100
                          }
                          valueStyle={{
                            color:
                              ((campaign.uniqueClicks || 0) /
                                campaign.totalContacts) *
                                100 >
                              (((campaign.uniqueOpens || 0) /
                                campaign.totalContacts) *
                                100) /
                                2
                                ? "#3f8600"
                                : "#cf1322",
                          }}
                          precision={2}
                          prefix={
                            ((campaign.uniqueClicks || 0) /
                              campaign.totalContacts) *
                              100 >
                            (((campaign.uniqueOpens || 0) /
                              campaign.totalContacts) *
                              100) /
                              2 ? (
                              <ArrowUpOutlined />
                            ) : (
                              <ArrowDownOutlined />
                            )
                          }
                          suffix="%"
                        />
                      </Card>
                    </Col>
                    {/* <Col xs={24} md={12}>
                      <Card bordered={true} style={{ display: "block" }}>
                        <Statistic
                          title="Processed"
                          value={campaign.ackowledge ?? 0}
                          suffix={<Text>/ {campaign.totalContacts ?? 0}</Text>}
                        />
                      </Card>
                    </Col> */}
                    <Col xs={24} md={24}>
                      <Card bordered={true} style={{ display: "block" }}>
                        <Statistic
                          title="Delivered"
                          value={campaign.totalSent}
                          suffix={
                            <Text>out of {campaign.totalContacts ?? 0}</Text>
                          }
                        />
                        <Progress
                          percent={(
                            (campaign.totalSent / campaign.totalContacts) *
                            100
                          ).toFixed(0)}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Divider />
                </>
              )}
              <Descriptions
                title={<Text>{campaign.totalContacts + " Contacts"}</Text>}
                items={[
                  {
                    key: 1,
                    label: "Tags",
                    children: campaign.tags.toString(),
                  },
                  {
                    key: 2,
                    label: "Sent On",
                    children: new Date(campaign.createdAt).toLocaleDateString(),
                  },
                ]}
              />
              <Title level={5} style={{ textAlign: "center" }}>
                {campaign.subject}
              </Title>
              <div dangerouslySetInnerHTML={{ __html: campaign.message }}></div>
            </Space>
          </>
        )}
      </Drawer>
    </>
  );
}
