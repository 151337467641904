import logo from "../../assets/logo-dark.svg";
import redmonklogo from "../../assets/redmonk-light.svg";
import EmpoweringStartups from "../../assets/empowering-startups.svg";
import pageBanner from "../../assets/page-banner-2.png";
import {
  ConfigProvider,
  Typography,
  Button,
  Row,
  Col,
  Tag,
  Card,
  Space,
  Flex,
  Avatar,
  Layout,
  Collapse,
  theme,
} from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

const Pricing = () => {
  const accessToken = localStorage.getItem("accessToken");

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#30EFD3",
          borderRadius: 6,
          colorLink: "#30EFD3",
        },
        components: {
          Layout: {
            bodyBg: "#0D1321",
          },
        },
      }}
    >
      <Layout>
        <div
          className="container"
          style={{ position: "fixed", left: 0, right: 0, zIndex: 999 }}
        >
          <header
            style={{
              background: "#fffcff",
              marginTop: "1rem",
              borderRadius: "6px",
              padding: "0.5rem",
            }}
          >
            <Flex gap="large" align="center" justify="space-between" wrap>
              <img
                src={logo}
                alt="Mailinflow Logo"
                height={30}
                style={{ marginLeft: "0.25rem" }}
              />
              <Flex gap="middle" align="center" justify="flex-start">
                <Button type="link" href="/">
                  Home
                </Button>
                <Button type="link" href="/about">
                  About
                </Button>
                <Button type="link" href="/pricing">
                  Pricing
                </Button>
              </Flex>
              {accessToken ? (
                <Button type="primary" href="/app">
                  Go to Dashboard
                </Button>
              ) : (
                <Flex gap="middle" align="center" justify="flex-start">
                  <Button type="default" href="/login">
                    Login
                  </Button>
                  <Button type="primary" href="/login">
                    Get Started
                  </Button>
                </Flex>
              )}
            </Flex>
          </header>
        </div>

        <main
          style={{
            backgroundImage: `url(${pageBanner})`,
            backgroundSize: "250px",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            backgroundRepeat: "repeat",
          }}
        >
          <div className="container">
            <section
              style={{
                padding: "10rem 0",
                textAlign: "center",
              }}
            >
              <Row gutter={30} align="center">
                <Col sm={24} md={12}>
                  <Title
                    style={{
                      marginBottom: "2.5rem",
                    }}
                  >
                    Simple Pricing, Unlimited Contacts
                  </Title>
                  <Title level={3} mark>
                    Start for free, pay only for the emails you send.
                  </Title>
                  <Button
                    type="primary"
                    size="large"
                    href="https://cal.com/d/uWZdkWEAbzT3L547YuKfua/book-a-demo-mailinflow"
                    target="_blank"
                  >
                    Book a Demo
                  </Button>
                </Col>
              </Row>
            </section>

            <section
              style={{
                padding: "4rem 0",
              }}
            >
              <Card
                title={
                  <>
                    <Title level={3}>Zero to Hero</Title>
                    <Paragraph>
                      No Fees, Just Features to Get You Started.
                    </Paragraph>
                  </>
                }
                extra={<Title>$0/m</Title>}
                bodyStyle={{
                  paddingTop: 0,
                }}
              >
                <Space size="large">
                  <Title level={5}>
                    <CheckCircleTwoTone twoToneColor="#30EFD3" /> Unlimited
                    Contacts
                  </Title>
                  <Title level={5}>
                    <CheckCircleTwoTone twoToneColor="#30EFD3" /> 100 Emails
                    Daily
                  </Title>
                  <Title level={5}>
                    <CheckCircleTwoTone twoToneColor="#30EFD3" /> Core Features
                  </Title>
                  <Title level={5}>
                    <CheckCircleTwoTone twoToneColor="#30EFD3" /> Effortless
                    Sending
                  </Title>
                </Space>
                <ul>
                  <li>
                    <Paragraph>
                      <strong>No Hidden Fees</strong> - Unlimited contact
                      storage is included, always free.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      <strong>Simple and Scalable</strong> - Only pay for the
                      emails you send.
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      <strong>Designed for Growth</strong> - Store as many
                      contacts as you need without worrying about added costs.
                    </Paragraph>
                  </li>
                </ul>

                <Button type="primary" href="/login" block>
                  Start Sending Emails for Free!
                </Button>
              </Card>
            </section>

            <section
              style={{
                padding: "4rem 0",
              }}
            >
              <Title
                level={2}
                style={{
                  marginBottom: "2.5rem",
                  textAlign: "center",
                }}
              >
                Frequently Asked Questions
              </Title>
              <Row gutter={30} align="center">
                <Col sm={24} md={12}>
                  <Space direction="vertical" size="large">
                    <Collapse
                      collapsible="header"
                      defaultActiveKey={["1"]}
                      items={[
                        {
                          key: "1",
                          label: "Why is contact storage free?",
                          children: (
                            <p>
                              We believe in empowering startups, and charging
                              for contact storage adds unnecessary barriers.
                              Grow your audience freely—only pay for the emails
                              you send.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "2",
                          label: "How do email credits work?",
                          children: (
                            <p>
                              After using your free daily limit of 100 emails,
                              additional emails are sent using credits from your
                              prepaid wallet. You must maintain a sufficient
                              balance to send more emails.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "3",
                          label: "What happens if I don’t use my credits?",
                          children: (
                            <p>
                              Your prepaid credits roll over and remain in your
                              wallet until they’re used—no expiration.
                            </p>
                          ),
                        },
                      ]}
                    />
                    <Collapse
                      collapsible="header"
                      items={[
                        {
                          key: "4",
                          label: "Is there a subscription fee?",
                          children: (
                            <p>
                              No subscription is required! Simply top up your
                              wallet as needed.
                            </p>
                          ),
                        },
                      ]}
                    />
                  </Space>
                </Col>
              </Row>
            </section>
          </div>
        </main>

        <footer>
          <Flex
            gap="small"
            align="center"
            vertical
            style={{ padding: "4rem 2rem 2rem" }}
          >
            <Text style={{ marginBottom: "0.25rem", fontSize: "65%" }}>
              Powered By
            </Text>
            <img src={redmonklogo} alt="Redmonk Logo" height={40} />
          </Flex>
          <Flex
            gap="large"
            align="center"
            justify="center"
            style={{ padding: "1rem 2rem 0" }}
            wrap
          >
            <Button
              type="link"
              href="https://redmonkstudios.com/about"
              target="_blank"
            >
              Our Studio
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/contact-us"
              target="_blank"
            >
              Contact
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Button>
            {/* <span style={{ margin: "0 10px" }}>|</span> */}
            <Button
              type="link"
              href="https://redmonkstudios.com/terms-of-service"
              target="_blank"
            >
              Terms of Service
            </Button>
          </Flex>
          <Flex
            gap="large"
            align="center"
            justify="center"
            style={{ padding: "1rem 2rem 0" }}
          >
            <Paragraph>
              &copy; Copyrights {new Date().getFullYear()} Redmonk Studios (OPC)
              Private Limited. All rights reserved.
            </Paragraph>
          </Flex>
        </footer>
      </Layout>
    </ConfigProvider>
  );
};

export default Pricing;
