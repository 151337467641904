import React from "react";
import logo from "../../assets/logo-dark.svg";
import { Editor } from "@tinymce/tinymce-react";
import { EditOutlined, FormOutlined } from "@ant-design/icons";
import { Button, Col, Select, Drawer, Form, Input, Row, Space } from "antd";

import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCampaign,
  updateCampaign,
} from "../../redux/actions/campaignsAction";
import { listTags } from "../../redux/actions/contactsAction";

export default function FormCampaign({ type, formData, fetchRecords }) {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.contacts.tags);
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);
  const [form] = Form.useForm();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};
  const windowOrigin = window.location.origin;

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSubmit = (values) => {
    values.message = editorRef.current.getContent();
    values.status = {
      type: "warning",
      description: "Draft",
    };
    if (type === "create") {
      dispatch(createCampaign(values))
        .then(() => {
          fetchRecords();
          setOpen(false);
          form.resetFields();
        })
        .catch((e) => console.log(e));
    } else {
      dispatch(updateCampaign(values, formData._id))
        .then(() => {
          fetchRecords();
          setOpen(false);
          form.resetFields();
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    dispatch(
      listTags({
        pageSize: 10,
        pageNum: 1,
      })
    ).catch((e) => console.log(e));
  }, []);

  return (
    <>
      {type === "create" ? (
        <Button
          icon={<FormOutlined />}
          title="Add Campaign"
          onClick={showDrawer}
        >
          Add
        </Button>
      ) : (
        <Button type="text" onClick={showDrawer} title="Edit Campaign">
          <EditOutlined />
        </Button>
      )}
      <Drawer
        title={type === "create" ? "New Campaign" : "Edit Campaign"}
        width={720}
        placement="right"
        maskClosable={false}
        destroyOnClose={true}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={form.submit}>
              Save
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ ...formData }}
          onFinish={onSubmit}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  {
                    required: true,
                    message: "Please enter subject line",
                  },
                ]}
              >
                <Input placeholder="Please enter subject line" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="tags"
                label="Tags"
                help="Campaign will be sent to all contacts if no tags are specified."
              >
                <Select
                  mode="tags"
                  placeholder="Please enter contact tags"
                  options={tags.map((tag) => ({
                    value: tag,
                    label: tag,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <br />
              <Editor
                tinymceScriptSrc={"/tinymce/tinymce.min.js"}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={
                  formData
                    ? formData.message
                    : "<div style='max-width: 600px; margin: auto;'><div class='mceEditable'></div><div class='mceNonEditable'><p style='font-size: 10pt; color: #bfbfbf; text-align: center'>You are receiving this email because you have visited our site or opted for our newsletter. Make sure our messages get to your Inbox (and not your bulk or junk folders).</p><p style='font-size: 10pt; color: #bfbfbf; text-align: center'><a href='" +
                      windowOrigin +
                      "/unsubscribe/${emailAddress}/" +
                      user.emailAddress +
                      "'>Unsubscribe</a> | <a href='" +
                      windowOrigin +
                      "/privacy-policy'>Privacy Policy</a></p><p style='font-size: 10pt; color: #bfbfbf; text-align: center'>Powered by<img src='" +
                      logo +
                      "' style='display: block; margin-top: 5px; margin-left: auto; margin-right: auto;' height='30'/></p></div></div>"
                }
                init={{
                  height: "calc(100vh - 320px)",
                  menubar: false,
                  selector: "textarea",
                  editable_root: false,
                  editable_class: "mceEditable",
                  convert_urls: true,
                  relative_urls: false,
                  remove_script_host: false,
                  /* enable title field in the Image dialog*/
                  image_title: true,
                  /* enable automatic uploads of images represented by blob or data URIs*/
                  automatic_uploads: true,
                  /*
                    URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                    images_upload_url: 'postAcceptor.php',
                    here we add custom filepicker only to Image dialog
                  */
                  file_picker_types: "image",
                  /* and here's our custom image picker*/
                  file_picker_callback: (cb, value, meta) => {
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*");

                    input.addEventListener("change", (e) => {
                      const file = e.target.files[0];

                      const reader = new FileReader();
                      reader.addEventListener("load", () => {
                        /*
                          Note: Now we need to register the blob in TinyMCEs image blob
                          registry. In the next release this part hopefully won't be
                          necessary, as we are looking to handle it internally.
                        */
                        const id = "blobid" + new Date().getTime();
                        /* global tinymce */
                        const blobCache =
                          tinymce.activeEditor.editorUpload.blobCache;
                        const base64 = reader.result.split(",")[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        /* call the callback and populate the Title field with the file name */
                        cb(blobInfo.blobUri(), { title: file.name });
                      });
                      reader.readAsDataURL(file);
                    });

                    input.click();
                  },
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "insertdatetime",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "media",
                    "table",
                    "wordcount",
                    "quickbars",
                    "emoticons",
                  ],
                  toolbar: [
                    "undo redo | fontfamily fontsize | bold italic underline | forecolor backcolor",
                    "alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | charmap emoticons link | searchreplace preview",
                  ],
                  quickbars_insert_toolbar: "image media table insertdatetime",
                  quickbars_selection_toolbar:
                    "bold italic underline strikethrough | code blockquote quicklink removeformat",
                }}
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
