import { publicPostApi } from "../../utils/apiHelper";

export const authValidate = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    publicPostApi("/auth/validate", payload)
      .then((response) => {
        if (response.status === 200) {
          const currentTime = new Date().getTime();
          const futureTime = 60000 * 60 * response.data.result.expireIn;
          localStorage.setItem("accessToken", response.data.result.accessToken);
          localStorage.setItem(
            "user",
            JSON.stringify(response.data.result.user)
          );
          localStorage.setItem(
            "expireAt",
            JSON.stringify(new Date(currentTime + futureTime).getTime())
          );
          resolve();
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const authRequest = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    publicPostApi("/auth/request", payload)
      .then((response) => {
        if (response.status === 200) {
          // do something
          resolve();
        } else {
          reject(response.message);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
