import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Spin } from "antd";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteContact } from "../../redux/actions/contactsAction";
import { deleteCampaign } from "../../redux/actions/campaignsAction";

export default function DeleteRecord({ collection, id, fetchRecords }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setLoading(true);
    dispatch(collection === "contact" ? deleteContact(id) : deleteCampaign(id))
      .then(() => {
        fetchRecords();
        setLoading(false);
        setIsModalOpen(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="link"
        danger
        onClick={showModal}
        title={"Delete " + collection}
      >
        <DeleteOutlined />
      </Button>
      <Spin spinning={loading}>
        <Modal
          title={"Delete " + collection}
          open={isModalOpen}
          okText="Yes"
          okType="danger"
          cancelText="No"
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>You are about to delete a record. Are you sure?</p>
        </Modal>
      </Spin>
    </>
  );
}
