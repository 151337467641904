import axios from "axios";
import { message } from "antd";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const publicGetApi = (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + path + "?" + new URLSearchParams(payload).toString())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response ? error.response.data.message : error.message,
          6
        );
        reject(error);
      });
  });
};

export const publicPostApi = (path, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + path, { ...payload })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response ? error.response.data.message : error.message,
          6
        );
        reject(error);
      });
  });
};

export const privatePostApi = (path, payload) => {
  if (
    !localStorage.getItem("accessToken") ||
    !(JSON.parse(localStorage.getItem("expireAt")) > new Date().getTime())
  ) {
    message.error("Session expired, please login again", 6);
    return;
  }
  const accessToken = localStorage.getItem("accessToken");
  return new Promise((resolve, reject) => {
    axios
      .post(
        baseUrl + path,
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response ? error.response.data.message : error.message,
          6
        );
        reject(error);
      });
  });
};

export const privateGetApi = (path, payload) => {
  if (
    !localStorage.getItem("accessToken") ||
    !(JSON.parse(localStorage.getItem("expireAt")) > new Date().getTime())
  ) {
    message.error("Session expired, please login again", 6);
    return;
  }
  const accessToken = localStorage.getItem("accessToken");
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + path + "?" + new URLSearchParams(payload).toString(), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response ? error.response.data.message : error.message,
          6
        );
        reject(error);
      });
  });
};

export const privatePutApi = (path, payload) => {
  if (
    !localStorage.getItem("accessToken") ||
    !(JSON.parse(localStorage.getItem("expireAt")) > new Date().getTime())
  ) {
    message.error("Session expired, please login again", 6);
    return;
  }
  const accessToken = localStorage.getItem("accessToken");
  return new Promise((resolve, reject) => {
    axios
      .put(
        baseUrl + path,
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response ? error.response.data.message : error.message,
          6
        );
        reject(error);
      });
  });
};

export const privateDeleteApi = (path, payload) => {
  if (
    !localStorage.getItem("accessToken") ||
    !(JSON.parse(localStorage.getItem("expireAt")) > new Date().getTime())
  ) {
    message.error("Session expired, please login again", 6);
    return;
  }
  const accessToken = localStorage.getItem("accessToken");
  return new Promise((resolve, reject) => {
    axios
      .delete(baseUrl + path, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response ? error.response.data.message : error.message,
          6
        );
        reject(error);
      });
  });
};

export const privateClientPostApi = (path, payload) => {
  if (
    !localStorage.getItem("clientAccessToken") ||
    !(JSON.parse(localStorage.getItem("clientExpireAt")) > new Date().getTime())
  ) {
    message.error("Session expired, please login again", 6);
    return;
  }
  const accessToken = localStorage.getItem("clientAccessToken");
  return new Promise((resolve, reject) => {
    axios
      .post(
        baseUrl + path,
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response ? error.response.data.message : error.message,
          6
        );
        reject(error);
      });
  });
};

export const privateClientPutApi = (path, payload) => {
  if (
    !localStorage.getItem("clientAccessToken") ||
    !(JSON.parse(localStorage.getItem("clientExpireAt")) > new Date().getTime())
  ) {
    message.error("Session expired, please login again", 6);
    return;
  }
  const accessToken = localStorage.getItem("clientAccessToken");
  return new Promise((resolve, reject) => {
    axios
      .put(
        baseUrl + path,
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response ? error.response.data.message : error.message,
          6
        );
        reject(error);
      });
  });
};
