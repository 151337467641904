import React, { useEffect, useState } from "react";
import { Space, Typography, Button, Tag, Table } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { listCampaigns } from "../../../redux/actions/campaignsAction";

import FormCampaign from "../../../components/forms/formCampaign";
import ViewCampaign from "../../../components/viewer/viewCampaign";
import DeleteRecord from "../../../components/modals/deleteRecord";
import SendEmail from "../../../components/modals/sendEmail";

const { Title, Text } = Typography;

export default function Campaigns() {
  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const campaignCount = useSelector((state) => state.campaigns.campaignCount);
  const [loading, setLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Status",
      dataIndex: "subject",
      key: "subject",
      render: (_, record) => (
        <>
          {record.status && (
            <Tag color={record.status.type}>{record.status.description}</Tag>
          )}
          {record.status.description === "Sent" && (
            <>
              <br></br>
              <Text>
                <small>{new Date(record.updatedAt).toLocaleString()}</small>
              </Text>
            </>
          )}
        </>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (_, record) => (
        <>
          {record.tags && record.tags.map((tag) => <Tag key={tag}>{tag}</Tag>)}
        </>
      ),
    },
    {
      title: "Creation",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, record) => (
        <Text>
          {new Date(record.createdAt).toLocaleString()}
          <br></br>
          <small>{record.createdBy}</small>
        </Text>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <ViewCampaign
            campaignId={record._id}
            fetchRecords={handleListChange}
          />
          {record.status.description === "Draft" && (
            <FormCampaign
              type="edit"
              formData={record}
              fetchRecords={handleListChange}
            />
          )}
          {record.status.description === "Draft" && (
            <SendEmail
              id={record._id}
              subject={record.subject}
              fetchRecords={handleListChange}
            />
          )}
          <DeleteRecord
            id={record._id}
            collection="campaigns"
            fetchRecords={handleListChange}
          />
        </Space>
      ),
    },
  ];

  const handleListChange = (pagination) => {
    if (!pagination) {
      pagination = tableParams.pagination;
    }
    setLoading(true);
    dispatch(
      listCampaigns({
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
      })
    )
      .then((response) => {
        setTableParams({
          pagination: { ...pagination },
        });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  useEffect(() => {
    handleListChange({ pageSize: 10, current: 1 });
  }, []);

  return (
    <div className="Campaigns">
      <Space
        style={{
          display: "flex",
          margin: "0 0.5rem 0.25rem 0.5rem",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            Campaigns
          </Title>
          <Text type="secondary">Manage your email campaigns</Text>
        </div>
        <Space size="small">
          {campaigns.filter((c) => c.status.description === "Pending")
            .length ? (
            <Button
              icon={<ReloadOutlined />}
              onClick={() => handleListChange({ pageSize: 10, current: 1 })}
            >
              Refresh
            </Button>
          ) : null}
          <FormCampaign type="create" fetchRecords={handleListChange} />
        </Space>
      </Space>
      <Table
        loading={loading}
        dataSource={campaigns}
        scroll={{
          x: 1000,
          y: "calc(100vh - 380px)",
        }}
        columns={columns}
        rowKey="_id"
        pagination={{
          ...tableParams.pagination,
          total: campaignCount,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (totalCount) => `Total  ${totalCount}  items`,
        }}
        onChange={handleListChange}
      />
    </div>
  );
}
